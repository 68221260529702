/*@import "tailwindcss/base";*/

/*@import "tailwindcss/components";*/

/*@import "tailwindcss/utilities";*/

.App {
  text-align: center;
  height: 80vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;
  /* box-shadow: rgb(0 0 0 / 25%) 0px 2px 20px; */
}

.restaurant-info-card-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;
}

.restaurant-card-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;
}

.edit-profile-card-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
