@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilroy-Semibold";
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("./assets/fonts/Gilroy-Semibold/Gilroy-Semibold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Semibold/Gilroy-Semibold.woff") format("woff"),
    url("./assets/fonts/Gilroy-Semibold/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("./assets/fonts/Gilroy-Medium/Gilroy-Medium.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Medium/Gilroy-Medium.woff") format("woff"),
    url("./assets/fonts/Gilroy-Medium/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-shadow {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.container {
  @apply max-w-[1920px]  mx-auto;
}
